import 'swiper/css';
import './../styles/main.scss';
import './../styles/main-rtl.scss';

import { disableEmptyLinks, mobileNavToggle, stickyHeader } from "./modules/header";
import { siteLoader } from "./modules/loader";
import { scrollAnimation } from "./modules/scroll-animation";
import { interactiveMap, productsSlider } from "./modules/homepage";
import { smoothScroll } from "./modules/smooth-scroll";

document.addEventListener('DOMContentLoaded', () => {
    siteLoader(scrollAnimation);
    stickyHeader();
    disableEmptyLinks();
    mobileNavToggle();
    productsSlider();

    smoothScroll();
    interactiveMap();
});