import Swiper from 'swiper';
import { Navigation, Autoplay } from 'swiper/modules';

export const productsSlider = () => {
    new Swiper('.homepage .products__slider', {
        modules: [Navigation, Autoplay],
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: false,
        },
        loop: true,
        speed: 600,
        slidesPerView: 'auto',
        spaceBetween: 20,
        navigation: {
            nextEl: '.products .products__slider-next',
            prevEl: '.products .products__slider-prev',
        },
    });

}

type Country = {
    id: string;
    products: string[]
}

export const interactiveMap = () => {
    const mapWrapper = document.querySelector('.homepage .home-map__map') as SVGAElement;

    if (!mapWrapper) return;

    const map = mapWrapper.querySelector('#world-map') as SVGAElement;

    const countriesData: Country[] = JSON.parse(map.dataset.countries || '') ?? {};
    const countries: Record<string, Country> = {}

    countriesData.forEach(country => {
        const path = map.querySelector(`#${country.id}`);
        path?.classList.add('highlight');


        countries[country.id] = country;
    });

    // Prep tooltip
    const tooltip = document.createElement('div');
    tooltip.className = 'map-tooltip';
    const tooltipTitle = document.createElement('h3');
    const tooltipList = document.createElement('ul');

    tooltip.append(tooltipTitle, tooltipList);

    map.addEventListener('mouseenter', () => {
        mapWrapper.appendChild(tooltip)
    })

    map.addEventListener('mouseleave', () => {
        mapWrapper.removeChild(tooltip)
    })

    map.addEventListener('mousemove', (e) => {
        const target = e.target as SVGAElement;
        const countryId = target.getAttribute('id') as string;
        const country = countries[countryId];

        if (target.tagName !== 'path' || !country) {
            tooltip.style.opacity = '0';
            return;
        }

        tooltipTitle.textContent = target.dataset.name || '';

        tooltipList.innerHTML = '';
        country.products.forEach(p => {
            const li = document.createElement('li');
            li.textContent = p;
            tooltipList.appendChild(li);
        });

        const mapBBox = map.getBoundingClientRect();

        let posTop = e.clientY - mapBBox.top - tooltip.clientHeight - 5;
        let posLeft = e.clientX - mapBBox.left - (tooltip.clientWidth);

        if ((e.clientX - tooltip.clientWidth + 20) <= 0) {
            posLeft = e.clientX - mapBBox.left;
        }

        if ((e.clientY - tooltip.clientHeight + 50) <= 0) {
            posTop = e.clientY - mapBBox.top + 5;
        }

        tooltip.style.top = `${posTop}px`;
        tooltip.style.left = `${posLeft}px`;
        tooltip.style.opacity = '1';
    });
}