/* SLIDE UP */
export const slideUp = (target: HTMLElement, duration: number = 500, classBased: boolean) => {
    target.style.transitionProperty = 'height, margin, padding';
    target.style.transitionDuration = duration + 'ms';
    target.style.boxSizing = 'border-box';
    target.style.height = target.offsetHeight + 'px';
    target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = '0';
    target.style.paddingTop = '0';
    target.style.paddingBottom = '0';
    target.style.marginTop = '0';
    target.style.marginBottom = '0';

    window.setTimeout(() => {
        target.style.display = 'none';
        target.style.removeProperty('height');
        target.style.removeProperty('padding-top');
        target.style.removeProperty('padding-bottom');
        target.style.removeProperty('margin-top');
        target.style.removeProperty('margin-bottom');
        target.style.removeProperty('overflow');
        target.style.removeProperty('transition-duration');
        target.style.removeProperty('transition-property');

        if (classBased) {
            target.style.removeProperty('display');
            target.classList.remove('toggle--open');
            target.classList.add('toggle--closed');
        }
    }, duration);
}

/* SLIDE DOWN */
export const slideDown = (target: HTMLElement, duration: number = 500, classBased: boolean) => {
    target.style.removeProperty('display');
    let display = window.getComputedStyle(target).display;
    if (display === 'none') display = 'block';
    target.style.display = display;
    let height = target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = '0';
    target.style.paddingTop = '0';
    target.style.paddingBottom = '0';
    target.style.marginTop = '0';
    target.style.marginBottom = '0';
    target.offsetHeight;
    target.style.boxSizing = 'border-box';
    target.style.transitionProperty = "height, margin, padding";
    target.style.transitionDuration = duration + 'ms';
    target.style.height = height + 'px';
    target.style.removeProperty('padding-top');
    target.style.removeProperty('padding-bottom');
    target.style.removeProperty('margin-top');
    target.style.removeProperty('margin-bottom');
    window.setTimeout(() => {
        target.style.removeProperty('height');
        target.style.removeProperty('overflow');
        target.style.removeProperty('transition-duration');
        target.style.removeProperty('transition-property');

        if (classBased) {
            target.style.removeProperty('display');
            target.classList.remove('toggle--closed');
            target.classList.add('toggle--open');
        }
    }, duration);
}

export const slideToggle = (
    target: HTMLElement,
    duration: number = 500,
    classBased: boolean = false
) => {
    if (window.getComputedStyle(target).display === 'none') {
        slideDown(target, duration, classBased);
    } else {
        slideUp(target, duration, classBased);
    }
}