export function siteLoader(callback?: () => void) {
    const loader = document.querySelector('.site-loader') as HTMLElement;

    setTimeout(() => {
        loader.classList.add('site-loader--hide');
        document.body.classList.remove('overflow-y-hidden');
    }, 500);

    setTimeout(() => {
        document.body.removeChild(loader);
        if (callback) {
            callback();
        }
    }, 800);
}