import { slideToggle } from "./slide-toggle";

export function stickyHeader() {
    const header = document.querySelector('.header') as HTMLElement;
    const MAX_SCROLL = header.offsetTop;

    window.addEventListener('scroll', () => {
        header.classList.toggle('sticky', window.scrollY > MAX_SCROLL);
    });
}


export function disableEmptyLinks() {
    const header = document.querySelector('.header') as HTMLElement;
    const emptyLinks = header.querySelectorAll('a[href="#"]') as NodeListOf<HTMLAnchorElement>;

    emptyLinks.forEach(link => {
        link.addEventListener('click', (e) => e.preventDefault());
    });
}

export function mobileNavToggle() {
    const header = document.querySelector('.header') as HTMLElement;
    const navbar = header.querySelector('.mobile-nav') as HTMLElement;
    const navbarClose = navbar.querySelector('.mobile-nav__close') as HTMLButtonElement;
    const navbarOpen = header.querySelector('.navbar-open') as HTMLButtonElement;
    const backdrop = navbar.querySelector('.mobile-nav__backdrop') as HTMLElement;
    let timeout: number;

    const dropdownToggles = navbar.querySelectorAll('.menu-item-has-children > a') as NodeListOf<HTMLAnchorElement>;

    dropdownToggles.forEach(dropdownToggle => {
        const submenu = dropdownToggle.parentElement?.querySelector('.sub-menu') as HTMLElement;

        dropdownToggle.addEventListener('click', (e) => {
            e.preventDefault();

            slideToggle(submenu, 300);
        })
    });

    navbarOpen.addEventListener('click', openNavbar);
    navbarClose.addEventListener('click', closeNavbar);
    backdrop.addEventListener('click', closeNavbar);

    function openNavbar() {
        navbar.classList.add('navbar-open');
        if (timeout) clearTimeout(timeout);
    }

    function closeNavbar() {
        navbar.classList.add('navbar-hide');

        timeout = setTimeout(() => {
            navbar.classList.remove('navbar-open', 'navbar-hide');
        }, 600);
    }

}