export const smoothScroll = () => {
    const anchors = document.querySelectorAll<HTMLAnchorElement>('a[href^="#"]');

    if (anchors.length === 0) return;

    anchors.forEach(anchor => {
        anchor.addEventListener('click', (event) => {
            const targetId = anchor.getAttribute('href')?.substring(1);
            const targetElement = document.getElementById(targetId as string);

            if (!targetElement) return;

            event.preventDefault();

            if (!targetElement) return;

            const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY - 130;

            window.scrollTo({
                top: targetPosition,
                behavior: 'smooth',
            });
        });
    });

}