export function scrollAnimation() {
    const elements = document.querySelectorAll('[data-scroll]') as NodeListOf<HTMLElement>;

    if (elements.length === 0) return;

    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                const target = entry.target as HTMLElement;
                target.setAttribute('data-visible', '');

                if (target.dataset.delay) {
                    target.style.setProperty('--animationDelay', `${target.dataset.delay}ms`);
                }

                observer.unobserve(target);
            }
        });
    }, { threshold: 0.5 });

    elements.forEach((el) => observer.observe(el));
}